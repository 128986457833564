import * as FeatherIcons from 'vue-feather-icons';

export default {
    items: [
        {
            name: 'Dashboard',
            type: 'dashboard',
            route: 'dashboard',
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.GridIcon,
        },
        {
            name: 'Content',
            children: [
                // { name: 'Layouts', route: 'layouts' },
                { name: 'Pages', route: 'pages', type: 'page' },
                { name: 'Panel library', route: 'panels', type: 'panel' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.LayersIcon,
        },
        {
            name: 'Assets',
            route: 'assets/0',
            type: 'asset',
            color: 'navy',
            group: 'general',
            iconComponent: FeatherIcons.ImageIcon,
        },

        {
            name: 'RSVP',
            route: 'subscribers',
            type: 'subscriber',
            // iconName: '',
            color: 'navy',
            group: 'client',
            iconComponent: FeatherIcons.CheckSquareIcon,
        },
        {
            name: 'Accounts',
            children: [
                { name: 'Users', route: 'users' },
                { name: 'Roles', route: 'roles' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'settings',
            iconComponent: FeatherIcons.LockIcon,
        },
        {
            name: 'Settings',
            children: [
                { name: 'Settings', route: 'settings', type: 'setting' },
                { name: 'Redirect URLs', route: 'redirect_urls', type: 'redirect_url' },
            ],
            // iconName: '',
            color: 'navy',
            group: 'settings',
            iconComponent: FeatherIcons.SettingsIcon,
        }
    ],
    defaultItem: 'Content',
}
