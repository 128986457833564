import _ from 'lodash';
import getHelpers from '../helpers';
const api = getHelpers('api');

// initial state
const state = {
    ...window.store,
    notifications: [],
    timestamp: Date.now(),
}

// getters
const getters = {
    customer: (state) => state.customer,
    notifications: (state) => state.notifications,
    timestamp: (state) => state.timestamp,
    pathNodes: (state) => state.pathNodes,
    route: (state) => (name, params) => {
        let path = '/' + _.get(state, ['routes', name]);

        _.forEach(params, (value, key) => {
            path = _.replace(path, new RegExp(`{${key}[?]?}`), value);
        })

        return path;
    }
}

const actions = {
    registerCustomer({ commit }, data) {
        return api
            .postData(`/../register`, {}, data)
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Your account has been created',
                });
                commit('updateProfile', res);
                return res;
            })
    },

    updateProfile({ commit }, data) {
        return api
            .patchData(`/my/profile`, {}, data)
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Profile update success',
                });
                commit('updateProfile', res);
                return res;
            })
    },

    updateCustomer({ commit, state }, data) {
        const customerId = _.get(state, 'customer.id');
        return api
            .patchData(`/my/customer/${customerId}`, {}, { origin: data })
            .then(res => {
                commit('addNotification', {
                    type: 'message',
                    message: 'Profile update success',
                });
                commit('updateCustomer', res);
            })
    },

    addNotification({ commit }, payload) {
        commit('addNotification', payload);
    },
}

// mutations
const mutations = {
    updateProfile(state, response) {
        _.set(state, 'customer', response);
        _.set(state, 'timestamp', Date.now());
    },

    updateCustomer(state, response) {
        _.set(state, 'customer', response);
        _.set(state, 'timestamp', Date.now());
    },

    addNotification(state, payload) {
        _.set(state, 'notifications', _.concat(state.notifications, [payload]));
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
